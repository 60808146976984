import _ from 'lodash';
import { CatalogInterface } from '@/models/interfaces/CatalogInterface';
import { ParentInterface } from '@/models/interfaces/ParentInterface';
import { ArticleInterface } from '@/models/interfaces/ArticleInterface';
import { CartInterface, CartItemInterface } from '@/models/interfaces/CartInterface';
import { CustomArticleInterface } from '@/models/interfaces/CustomArticleInterface';

class State {
  currentArticle: ArticleInterface;

  cart: CartInterface = {
    commission: '',
    note: '',
    cartItems: [],
    customArticles: [],
    dealer: '',
  };

  currentCatalog: CatalogInterface;

  currentParent: ParentInterface;

  isSidebarMenuCollapsed = false;
}

const mutations = {
  deleteCartItem(state: State, cartItem: CartItemInterface) {
    for (let i = 0; i < state.cart.cartItems.length; i++) {
      if (_.isEqual(cartItem, state.cart.cartItems[i])) {
        state.cart.cartItems.splice(i, 1);
        return;
      }
    }
  },
  setCart(state: State, cart: CartInterface) {
    state.cart = cart;
  },
  removeCart(state: State) {
    initializeCart(state);
  },
  setCurrentArticle(state: State, article: ArticleInterface) {
    state.currentArticle = article;
  },
  setCurrentCatalog(state: State, catalog: CatalogInterface) {
    state.currentCatalog = catalog;
  },
  setCurrentParent(state: State, parent: ParentInterface) {
    state.currentParent = parent;
  },
  addCustomArticle(state: State, customArticle: CustomArticleInterface) {
    if (!state.cart) {
      initializeCart(state);
    }
    if (state.cart.customArticles) {
      state.cart.customArticles.push(customArticle);
    } else {
      state.cart.customArticles = [customArticle];
    }
  },
  updateCustomArticle(state: State, customArticleUpdated: CustomArticleInterface) {
    const index = state.cart.customArticles.findIndex((customArticle) => customArticle._id === customArticleUpdated._id);
    state.cart.customArticles[index] = customArticleUpdated;
  },
  removeCustomArticle(state: State, customArticleToRemove: CustomArticleInterface) {
    state.cart.customArticles = state.cart.customArticles.filter((ca) => ca._id !== customArticleToRemove._id);
  },
  toggleSidebarMenu(state: State, toggledState: boolean) {
    state.isSidebarMenuCollapsed = toggledState;
  },
};

const actions = {
  deleteCartItem(context, cartItem: CartItemInterface) {
    context.commit('deleteCartItem', cartItem);
  },
  setCart(context, cart: CartInterface) {
    context.commit('setCart', cart);
  },
  removeCart(context) {
    context.commit('removeCart');
  },
  setCurrentArticle(context, article: ArticleInterface) {
    context.commit('setCurrentArticle', article);
  },
  setCurrentCatalog(context, catalog: CatalogInterface) {
    context.commit('setCurrentCatalog', catalog);
  },
  setCurrentParent(context, parent: ParentInterface) {
    context.commit('setCurrentParent', parent);
  },
  addCustomArticle(context, customArticle: CustomArticleInterface) {
    context.commit('addCustomArticle', customArticle);
  },
  updateCustomArticle(context, customArticle: CustomArticleInterface) {
    context.commit('updateCustomArticle', customArticle);
  },
  removeCustomArticle(context, customArticleToRemove: CustomArticleInterface) {
    context.commit('removeCustomArticle', customArticleToRemove);
  },
  toggleSidebarMenu(context, toggledState: boolean) {
    context.commit('toggleSidebarMenu', toggledState);
  },
};

const initializeCart = (state: State) => {
  state.cart = {
    commission: '',
    note: '',
    cartItems: [],
    customArticles: [],
    dealer: '',
  };
};

export default {
  namespaced: true,
  actions,
  mutations,
  State,
};
