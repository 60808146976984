// Check if user is allowed to see page.
// First check if user is logged in or not and has company set.
import { store } from '@/store';
import { ERROR, LOGIN } from '@/router/route-names';
import _ from 'lodash';

/* export function isUserAllowed(type: string, next: any, router: any) {
  if (store.state && {}.hasOwnProperty.call(store.state, 'producer')) {
    // @ts-ignore
    if (store.state.producer.company.type === type) {
      next();
      return;
    }
  }
  router.push({ name: ERROR }).then();
} */

export function authDealer({ next, router }) {
  if (window.location.href.includes('3.64.204.205:8081')) { // TODO remove after while
    window.location.replace(
      _.replace(window.location.href, '3.64.204.205:8081', 'app.easy2b.at'),
    );
  }
  // @ts-ignore
  if (!store.state.user.user) {
    router.push({ name: LOGIN }).then();
  }

  if (store.state && {}.hasOwnProperty.call(store.state, 'producer')) {
    // @ts-ignore
    if (store.state.producer.company.type === 'dealer') {
      next();
      return;
    }
  }
  router.push({ name: ERROR }).then();
}

export function authProducer({ next, router }) {
  if (window.location.href.includes('3.64.204.205:8081')) { // TODO remove after while
    window.location.replace(
      _.replace(window.location.href, '3.64.204.205:8081', 'app.easy2b.at'),
    );
  }
  if (store.state && {}.hasOwnProperty.call(store.state, 'producer')) {
    // @ts-ignore
    if (store.state.producer.company.type === 'producer') {
      next();
      return;
    }
  }
  router.push({ name: ERROR }).then();
}

export function redirectToNewInstance({ next }) { // TODO remove after while
  if (window.location.href.includes('3.64.204.205:8081')) { // TODO remove after while
    window.location.replace(
      _.replace(window.location.href, '3.64.204.205:8081', 'app.easy2b.at'),
    );
  }
  next();
}
