import dealer from './dealer';
import intern from './intern';
import producer from './producer';
import user from './user';

export default {
  dealer,
  intern,
  producer,
  user,
};
