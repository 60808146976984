import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import dotenv from 'dotenv';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import { store } from './store';

const app = createApp(App)
  .use(router)
  .use(store)
  .directive('focus', {
    mounted: (el) => {
      el.focus();
    },
  });

// Globally register all element plus icons, which is required says the documentation.
for (let i = 0; i < Object.entries(ElementPlusIconsVue).length; i++) {
  const [key, component] = Object.entries(ElementPlusIconsVue)[i];
  app.component(key, component);
}
app.use(ElementPlus);
app.mount('#app');

dotenv.config();
