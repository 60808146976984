import { RouteRecordRaw } from 'vue-router';
import {
  DEALER_ALL_CATALOGS,
  DEALER_ARTICLE_VIEW,
  DEALER_CART_DETAIL,
  DEALER_CATALOG_DETAIL,
  DEALER_DASHBOARD,
  DEALER_MY_CARTS,
  DEALER_MY_CATALOGS,
  DEALER_PRINT_PREVIEW,
  DEALER_SETTINGS, ERROR, LOGIN, INTERN_COMPANY, INTERN_DASHBOARD, INTERN_LOGIN_PAGE,
  PRODUCER_ARTICLE_VIEW,
  PRODUCER_ARTICLECATEGORIES,
  PRODUCER_CARTS,
  PRODUCER_CATALOG_VIEW,
  PRODUCER_CATEGORY_VIEW,
  PRODUCER_DASHBOARD,
  PRODUCER_DEALER_DETAIL_VIEW,
  PRODUCER_MODEL_VIEW,
  PRODUCER_MYCATALOGS, PRODUCER_MYDEALERS,
  PRODUCER_MYLIBRARY, PRODUCER_PRICE_MANAGEMENT,
  PRODUCER_SETTINGS_VIEW,
  PRODUCER_VARIATION_VIEW,
  PRODUCER_VARIATIONCOMBINATION,
} from '@/router/route-names';
import { authDealer, authProducer, redirectToNewInstance } from '@/router/middleware';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginPage.vue'),
    meta: {
      middleware: redirectToNewInstance,
    },
  },
  {
    path: '/login',
    name: LOGIN,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginPage.vue'),
    meta: {
      middleware: redirectToNewInstance,
    },
  },
  // DEALER ROUTES
  {
    path: '/article',
    name: DEALER_ARTICLE_VIEW,
    component: () => import('../views/dealer/ArticleView.vue'),
    meta: {
      middleware: authDealer,
    },
  },
  {
    path: '/dashboard',
    name: DEALER_DASHBOARD,
    component: () => import('../views/dealer/Dashboard.vue'),
    meta: {
      middleware: authDealer,
    },
  },
  {
    path: '/my-catalogs',
    name: DEALER_MY_CATALOGS,
    component: () => import('../views/dealer/MyCatalogs.vue'),
    meta: {
      middleware: authDealer,
    },
  },
  {
    path: '/all-catalogs',
    name: DEALER_ALL_CATALOGS,
    component: () => import('../views/dealer/AllCatalogs.vue'),
    meta: {
      middleware: authDealer,
    },
  },
  {
    path: '/catalog-detail',
    name: DEALER_CATALOG_DETAIL,
    component: () => import('../views/dealer/CatalogDetail.vue'),
    meta: {
      middleware: authDealer,
    },
  },
  {
    path: '/carts',
    name: DEALER_MY_CARTS,
    component: () => import('../views/dealer/MyCarts.vue'),
    meta: {
      middleware: authDealer,
    },
  },
  {
    path: '/cart-detail',
    name: DEALER_CART_DETAIL,
    component: () => import('../views/dealer/CartDetail.vue'),
    meta: {
      middleware: authDealer,
    },
  },
  {
    path: '/dealer-settings',
    name: DEALER_SETTINGS,
    component: () => import('../views/dealer/DealerSettings.vue'),
    meta: {
      middleware: authDealer,
    },
  },
  {
    path: '/print-preview',
    name: DEALER_PRINT_PREVIEW,
    component: () => import('../views/dealer/PrintPreview.vue'),
  },

  // PRODUCER ROUTES
  {
    path: '/producer/dealer/:id',
    name: PRODUCER_DEALER_DETAIL_VIEW,
    component: () => import('../views/producer/MyDealerDetail.vue'),
    meta: {
      middleware: authProducer,
    },
  },
  {
    path: '/producer/articlecategories/:id',
    name: PRODUCER_ARTICLECATEGORIES,
    component: () => import('../views/producer/ArticleCategories.vue'),
    meta: {
      middleware: authProducer,
    },
  },
  {
    path: '/producer/variation/:id',
    name: PRODUCER_VARIATION_VIEW,
    component: () => import('../views/producer/VariationView.vue'),
    meta: {
      middleware: authProducer,
    },
  },
  {
    path: '/producer/variationgroups/:id',
    name: 'Producer Variation Groups',
    component: () => import('../views/producer/VariationGroups.vue'),
    meta: {
      middleware: authProducer,
    },
  },
  {
    path: '/producer/variation/combination/:id',
    name: PRODUCER_VARIATIONCOMBINATION,
    component: () => import('../views/producer/VariationCombination.vue'),
    meta: {
      middleware: authProducer,
    },
  },
  {
    path: '/producer/carts',
    name: PRODUCER_CARTS,
    component: () => import('../views/producer/Carts.vue'),
    meta: {
      middleware: authProducer,
    },
  },
  {
    path: '/producer/dashboard',
    name: PRODUCER_DASHBOARD,
    component: () => import('../views/producer/Dashboard.vue'),
    meta: {
      middleware: authProducer,
    },
  },
  {
    path: '/producer/my-catalogs',
    name: PRODUCER_MYCATALOGS,
    component: () => import('../views/producer/MyCatalogs.vue'),
    meta: {
      middleware: authProducer,
    },
  },
  {
    path: '/producer/my-library',
    name: PRODUCER_MYLIBRARY,
    component: () => import('../views/producer/MyLibrary.vue'),
    meta: {
      middleware: authProducer,
    },
  },
  {
    path: '/producer/catalog',
    name: PRODUCER_CATALOG_VIEW,
    component: () => import('../views/producer/CatalogView.vue'),
    meta: {
      middleware: authProducer,
    },
  },
  {
    path: '/producer/category',
    name: PRODUCER_CATEGORY_VIEW,
    // TODO: Holy fuck, something wrong with airbnb config?
    // eslint-disable-next-line import/no-unresolved
    component: () => import('../views/producer/CategoryView.vue'),
    meta: {
      middleware: authProducer,
    },
  },
  {
    path: '/producer/model',
    name: PRODUCER_MODEL_VIEW,
    component: () => import('../views/producer/ModelView.vue'),
    meta: {
      middleware: authProducer,
    },
  },
  {
    path: '/producer/article',
    name: PRODUCER_ARTICLE_VIEW,
    component: () => import('../views/producer/ArticleView.vue'),
    meta: {
      middleware: authProducer,
    },
  },
  {
    path: '/producer/settings',
    name: PRODUCER_SETTINGS_VIEW,
    component: () => import('../views/producer/CompanySettings.vue'),
    meta: {
      middleware: authProducer,
    },
  },
  {
    path: '/producer/my-dealers',
    name: PRODUCER_MYDEALERS,
    component: () => import('../views/producer/MyDealers.vue'),
    meta: {
      middleware: authProducer,
    },
  },
  {
    path: '/producer/price-management',
    name: PRODUCER_PRICE_MANAGEMENT,
    component: () => import('../views/producer/PriceManagement.vue'),
    meta: {
      middleware: authProducer,
    },
  },
  {
    path: '/error',
    name: ERROR,
    component: () => import('../views/Error.vue'),
    meta: {
      middleware: redirectToNewInstance,
    },
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/error',
  },

  // INTERN ROUTES
  {
    path: '/intern/login',
    name: INTERN_LOGIN_PAGE,
    component: () => import('../views/intern/LoginPage.vue'),
    meta: {
      middleware: redirectToNewInstance,
    },
  },
  {
    path: '/intern/dashboard',
    name: INTERN_DASHBOARD,
    component: () => import('../views/intern/Dashboard.vue'),
    meta: {
      middleware: redirectToNewInstance,
    },
  },
  {
    path: '/intern/company',
    name: INTERN_COMPANY,
    component: () => import('../views/intern/Company.vue'),
    meta: {
      middleware: redirectToNewInstance,
    },
  },
];

export default routes;
