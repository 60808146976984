import { UserInterface } from '@/models/interfaces/UserInterface';

class State {
  user: UserInterface;
}

const mutations = {
  setUser(state: State, user: UserInterface) {
    state.user = user;
  },
};

const actions = {
  setUser(context: any, user: UserInterface) {
    context.commit('setUser', user);
  },
};

export default {
  namespaced: false,
  actions,
  mutations,
  State,
};
