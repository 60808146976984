import { CompanyInterface } from '@/models/interfaces/CompanyInterface';
import { UserInterface } from '@/models/interfaces/UserInterface';

class State {
  currentCompany: CompanyInterface;

  currentUser: UserInterface;
}

const mutations = {
  setCompany(state: State, company: CompanyInterface) {
    state.currentCompany = company;
  },
  setUser(state: State, user: UserInterface) {
    state.currentUser = user;
  },
};

const actions = {
  setUser(context, user: UserInterface) {
    context.commit('setUser', user);
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  State,
};
