<template>
  <router-view />
</template>

<style lang="scss">
@import './assets/scss/main.scss';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

// TODO
// https://stackoverflow.com/questions/60878489/uncaught-syntaxerror-unexpected-token-vuejs-pwa
// https://cli.vuejs.org/config/#global-cli-config
