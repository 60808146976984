import { CatalogInterface } from '@/models/interfaces/CatalogInterface';
import { CategoryInterface } from '@/models/interfaces/CategoryInterface';
import { ModelInterface } from '@/models/interfaces/ModelInterface';
import { ArticleInterface } from '@/models/interfaces/ArticleInterface';
import { ArticleCategoryInterface } from '@/models/interfaces/ArticleCategoryInterface';
import { ParentInterface } from '@/models/interfaces/ParentInterface';
import { CompanyInterface } from '@/models/interfaces/CompanyInterface';
import { VariationInterface } from '@/models/interfaces/VariationInterface';

class State {
  catalogs: Array<CatalogInterface>;

  catalogRequestsLength: number;

  currentCatalog: CatalogInterface;

  currentCategory: CategoryInterface;

  currentCategories: Array<CategoryInterface>;

  currentDealer: CompanyInterface;

  currentModel: ModelInterface;

  currentModels: Array<ModelInterface>;

  currentArticle: ArticleInterface;

  currentArticles: Array<ArticleInterface>;

  articleCategories: Array<ArticleCategoryInterface> = [];

  currentArticleCategory: ArticleCategoryInterface;

  currentParent: ParentInterface;

  company: CompanyInterface;

  variations: Array<VariationInterface> = [];

  showIndexTree = true;

  isSidebarMenuCollapsed = false;
}

const mutations = {
  pushCatalog(state: State, catalog: CatalogInterface) {
    state.catalogs.unshift(catalog);
  },
  setCatalogs(state: State, catalogs: Array<CatalogInterface>) {
    state.catalogs = catalogs;
  },
  setCatalogRequestsLength(state: State, catalogRequestsLength: number) {
    state.catalogRequestsLength = catalogRequestsLength;
  },
  setCurrentCatalog(state: State, catalog: CatalogInterface) {
    state.currentCatalog = catalog;
  },
  setCurrentParent(state: State, parent: ParentInterface) {
    state.currentParent = parent;
  },
  setCurrentCategory(state: State, category: CategoryInterface) {
    state.currentCategory = category;
  },
  setCurrentCategories(state: State, categories: Array<CategoryInterface>) {
    state.currentCategories = categories;
  },
  addCurrentCategory(state: State, category: CategoryInterface) {
    state.currentCategories.push(category);
  },
  setCurrentModel(state: State, model: ModelInterface) {
    state.currentModel = model;
  },
  setCurrentModels(state: State, models: Array<ModelInterface>) {
    state.currentModels = models;
  },
  addCurrentModel(state: State, model: ModelInterface) {
    state.currentModels.push(model);
  },
  setCurrentArticle(state: State, article: ArticleInterface) {
    state.currentArticle = article;
  },
  setCurrentArticles(state: State, articles: Array<ArticleInterface>) {
    state.currentArticles = articles;
  },
  addCurrentArticle(state: State, model: ArticleInterface) {
    state.currentArticles.push(model);
  },
  setCompany(state: State, company: CompanyInterface) {
    state.company = company;
  },
  // TODO Set Article categories probably deprecated
  setArticleCategories(state: State, articleCategories: Array<ArticleCategoryInterface>) {
    state.articleCategories = articleCategories;
  },
  currentArticleCategory(state: State, articleCategory: ArticleCategoryInterface) {
    state.currentArticleCategory = articleCategory;
  },
  // TODO probably not used anymore
  setCurrentDealer(state: State, currentDealer: CompanyInterface) {
    state.currentDealer = currentDealer;
  },
  setVariations(state: State, variations: Array<VariationInterface>) {
    state.variations = variations;
  },
  addVariation(state: State, variation: VariationInterface) {
    state.variations.push(variation);
  },
  toggleIndexTree(state: State, toggle: boolean) {
    state.showIndexTree = toggle;
  },
  toggleSidebarMenu(state: State, toggledState: boolean) {
    console.log('MUT');
    console.log(toggledState);
    console.log(state);
    state.isSidebarMenuCollapsed = toggledState;
  },
};

const actions = {
  pushCatalog(context, catalog: CatalogInterface) {
    context.commit('pushCatalog', catalog);
  },
  setCatalogs(context, catalogs: Array<CatalogInterface>) {
    context.commit('setCatalogs', catalogs);
  },
  setCatalogRequestsLength(context, catalogRequestsLength: number) {
    context.commit('setCatalogRequestsLength', catalogRequestsLength);
  },
  setCurrentCatalog(context, catalog: CatalogInterface) {
    context.commit('setCurrentCatalog', catalog);
  },
  setCurrentParent(context, parent: ParentInterface) {
    context.commit('setCurrentParent', parent);
  },
  setCurrentCategory(context, category: CategoryInterface) {
    context.commit('setCurrentCategory', category);
  },
  setCurrentCategories(context, categories: Array<CategoryInterface>) {
    context.commit('setCurrentCategories', categories);
  },
  addCurrentCategory(context, category: CategoryInterface) {
    context.commit('addCurrentCategory', category);
  },
  setCurrentModel(context, model: ModelInterface) {
    context.commit('setCurrentModel', model);
  },
  setCurrentModels(context, models: Array<ModelInterface>) {
    context.commit('setCurrentModels', models);
  },
  addCurrentModel(context, model: ModelInterface) {
    context.commit('addCurrentModels', model);
  },
  setCurrentArticle(context, article: ArticleInterface) {
    context.commit('setCurrentArticle', article);
  },
  setCurrentArticles(context, articles: Array<ArticleInterface>) {
    context.commit('setCurrentArticles', articles);
  },
  addCurrentArticle(context, article: ArticleInterface) {
    context.commit('addCurrentArticle', article);
  },
  setCompany(context, company: CompanyInterface) {
    context.commit('setCompany', company);
  },
  setArticleCategories(context, articleCategories: Array<ArticleCategoryInterface>) {
    context.commit('setArticleCategories', articleCategories);
  },
  currentArticleCategory(context, articleCategory: ArticleCategoryInterface) {
    context.commit('currentArticleCategory', articleCategory);
  },
  setCurrentDealer(context, dealer: CompanyInterface) {
    context.commit('setCurrentDealer', dealer);
  },
  setVariations(context, variations: Array<VariationInterface>) {
    context.commit('setVariations', variations);
  },
  addVariation(context, variation: VariationInterface) {
    context.commit('addVariation', variation);
  },
  toggleIndexTree(context, toggle: boolean) {
    context.commit('toggleIndexTree', toggle);
  },
  toggleSidebarMenu(context, toggledState: boolean) {
    context.commit('toggleSidebarMenu', toggledState);
  },
};

export default {
  namespaced: false,
  actions,
  mutations,
  State,
};
