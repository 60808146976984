export const DEALER_DASHBOARD = 'Dealer Dashboard';
export const DEALER_ALL_CATALOGS = 'Dealer All Catalogs';
export const DEALER_ARTICLE_VIEW = 'Dealer Article View';
export const DEALER_MY_CATALOGS = 'Dealer My Catalogs';
export const DEALER_MY_CARTS = 'Dealer My Carts';
export const DEALER_CART_DETAIL = 'Dealer Cart Detail';
export const DEALER_CATALOG_DETAIL = 'Dealer Catalog Detail';
export const DEALER_SETTINGS = 'Dealer Settings';
export const DEALER_PRINT_PREVIEW = 'Dealer Print Preview';

export const ERROR = 'Error';
export const LOGIN = 'LoginPage';

export const PRODUCER_DASHBOARD = 'Producer Dashboard';
export const PRODUCER_MYCATALOGS = 'Producer MyCatalogs';
export const PRODUCER_MYDEALERS = 'Producer MyDealers';
export const PRODUCER_MYLIBRARY = 'Producer MyLibrary';
export const PRODUCER_ARTICLECATEGORIES = 'Producer Articlecategories';
export const PRODUCER_VARIATIONGROUPS = 'Producer Variation Groups';
export const PRODUCER_VARIATIONCOMBINATION = 'Producer Variation Combination';
export const PRODUCER_DEALER_DETAIL_VIEW = 'Producer Dealer View';
export const PRODUCER_CARTS = 'Producer Carts';
export const PRODUCER_CATALOG_VIEW = 'Catalog';
export const PRODUCER_CATEGORY_VIEW = 'Category';
export const PRODUCER_MODEL_VIEW = 'MODEL';
export const PRODUCER_ARTICLE_VIEW = 'ARTICLE';
export const PRODUCER_VARIATION_VIEW = 'VARIATION';
export const PRODUCER_SETTINGS_VIEW = 'PRODUCER SETTINGS';
export const PRODUCER_PRICE_MANAGEMENT = 'PRODUCER Price Management';

export const INTERN_LOGIN_PAGE = 'Intern Login Page';
export const INTERN_DASHBOARD = 'Intern Dashboard';
export const INTERN_COMPANY = 'Intern Company';
