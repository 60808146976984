import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import modules from './modules';

export const store = createStore({
  modules,
  plugins: [
    createPersistedState({
      paths: [
        'dealer',
        'producer',
        'user',
      ],
    }),
  ],
});
